import AuthService from "../../features/auth/service";
import { push } from "connected-react-router";

export interface IApiService {
  ajax: <T>(request: string | Request) => Promise<T>;
  getDefaultRequestInit: () => RequestInit;
}
export class ApiService implements IApiService {
  // tslint:disable-next-line:ban-types
  public api?: Function;

  // tslint:disable-next-line:no-unnecessary-initializer
  constructor(mockFetch?: () => Promise<Response>) {
    if (mockFetch) {
      this.api = mockFetch;
    }
  }

  public ajax<T>(request: string | Request): Promise<T> {
    if (this.api) {
      return this.api(request)
        .then(this.handleRequest)
        .catch(this.handleError);
    }

    let newRequest: Request;
    if (typeof request === "string") {
      newRequest = new Request(request, this.getDefaultRequestInit());
    } else {
      newRequest = request;
    }

    return fetch(newRequest)
      .then(this.handleRequest)
      .catch(this.handleError);
  }

  public getDefaultRequestInit(method = "GET") {
    const { REACT_APP_ENV } = process.env;
    const isLocal = REACT_APP_ENV === "local";
    //Check if user is logged in
    AuthService.isLoggedIn;
    const init: RequestInit = {
      cache: "default",
      headers: {
        Authorization: `Bearer ${AuthService.getJWTToken()!}`,
        "Content-Type": "application/json",
        ...(isLocal ? { "x-user-email": "csaldanha@loyalty.com" } : null)
      },
      method,
      mode: "cors"
    };
    return init;
  }
  
  private handleRequest(response: Response) {
    if (!response.ok) {
      return response.json().then(error => {
        if (
          response.status === 504 ||
          (error.message &&
            error.message.includes("Endpoint request timed out"))
        ) {
          error.shouldRetry = true;
        }
        // Store the errors status code for reference
        error.statusCode = response.status;
        throw error;
      });
    }

    const contentType = response.headers.get("content-type");
    if (
      response.status !== 204 &&
      contentType &&
      contentType.indexOf("application/json") !== -1
    ) {
      return response.json();
    } else {
      return response.text();
    }
  }

  private handleError(err: Error) {
    console.error(err);
    throw err;
  }
}

const apiService = new ApiService();

export default apiService;
